@use "../../../../../../../styles/base";

.section_wrapper {
  @include base.section-wrapper;
}

.experience_section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  z-index: 10;
  @media screen and (max-width: base.$breakpoint-md) {
    min-height: 85vh;
  }
}

.experience_section__name {
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: base.$coffee-dark;
  text-transform: uppercase;
  position: absolute;
  left: 0;

  @media screen and (max-width: base.$breakpoint-lg) {
    font-size: 16px;
    padding: 1.5rem;
    margin-top: 3rem;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    padding: 1.5rem 0;
  }
}

.experience_section__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 5rem 10rem 5rem;

  @media screen and (max-width: base.$breakpoint-lg) {
    align-items: flex-start;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    padding: 3rem;
  }

  gap: 50px;

  flex: 2 1 auto; // child of flex item
  flex-wrap: wrap;
}

.experience_section__content {
  width: 400px;

  @media screen and (max-width: base.$breakpoint-lg) {
    padding: 1.3rem;
    width: 600px;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    width: 400px;
  }

  @media screen and (max-width: base.$breakpoint-sm) {
    padding: 5px;
    width: 250px;
  }
}

.experience_section__title {
  font-size: 22px;

  color: base.$coffee-dark;
  font-weight: bold;
  text-transform: uppercase;

  @media screen and (max-width: base.$breakpoint-md) {
    font-size: 20px;
  }
}

.experience_section__description {
  font-size: 13px;
  font-weight: 400;
}

.experience_section__sub_title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
  color: base.$coffee-dark;

  @media screen and (max-width: base.$breakpoint-lg) {
    margin-top: 15px;
  }
}

.experience_section__video {
  width: 694px;
  height: 450px;
  padding: 1.5rem;

  @media screen and (max-width: base.$breakpoint-xl) {
    width: 432px;
    height: 290px;
  }

  @media screen and (max-width: base.$breakpoint-lg) {
    display: none;
  }
}

.experience_section__mobile_video {
  display: none;
  width: 100%;
  max-height: 80vh;
  position: relative;
  top: -4rem;
  z-index: 15;
  padding: 0;

  @media screen and (max-width: base.$breakpoint-lg) {
    display: block;
    top: -10rem;
  }
}

.section_animated_back {
  @include base.section-animated-back;

  .experience_section__shape_art {
    width: 300px;
    position: absolute;
    left: 20rem;
    top: 1rem;

    @media screen and (max-width: base.$breakpoint-lg) {
      left: -3rem;
      top: 73vh;
    }
    @media screen and (max-width: base.$breakpoint-md) {
      top: 52vh;
    }
  }

  .experience_section__shape_room {
    width: 350px;
    position: absolute;

    right: 3rem;
    bottom: 7rem;

    @media screen and (max-width: base.$breakpoint-lg) {
      right: -2rem;
      bottom: 12.5vh;
    }
  }

  .experience_section__shape_1 {
    width: 200px;

    position: absolute;
    right: -6rem;
    bottom: 0;

    @media screen and (max-width: base.$breakpoint-lg) {
      display: none;
    }
  }

  .experience_section__shape_2 {
    width: 150px;

    position: absolute;
    left: -3rem;
    bottom: 5rem;
    z-index: 10;

    @media screen and (max-width: base.$breakpoint-lg) {
      display: none;
    }
  }

  .experience_section__shape_3 {
    width: 150px;

    position: absolute;
    left: -1rem;
    bottom: 11rem;

    @media screen and (max-width: base.$breakpoint-lg) {
      display: none;
    }
  }
}
