@use '../../../../../../../styles/base';

.discover_section {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.discover_section__wrapper {
  display: grid;
  padding: 1.5rem;

  position: absolute;
  inset: 0;
  z-index: 1000;
}

.discover_section__title {
  width: 680px;
  font-size: 45px;
  color: #fff;

  text-transform: uppercase;
  font-weight: 700;

  position: relative;
  left: 15%;

  @media screen and (max-width: base.$breakpoint-lg) {
    font-size: 45px;
    left: 10%;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    width: 100%;
    font-size: 30px;
    left: 0;
  }

  @media screen and (max-width: base.$breakpoint-sm) {
    font-size: 25px;
    width: 310px;
    margin-top: 0;
  }
}

.discover_section__background {
  width: 100%;
  height: 100vh;

  position: absolute;
  inset: 0;
}

.discover_section__swiper {
  display: grid;
  margin: auto;
  justify-items: center;

  background: none;
  border: none;
  cursor: pointer;

  transition: transform .3s ease-in-out;
  &:hover {
    transform: scale(1.15);
  }

  span {
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 16px;
  }
}
