@use "../../../../styles/base";
@use "../../../../styles/main";

.admin {
  background-color: base.$coffee-back;
  min-height: calc(100vh - 292px);

  &__block {
    display: flex;
    align-items: stretch;
    padding-right: 40px;
  }

  &__panel {
    max-width: 320px;
    width: 100%;
    background-color: base.$grey-light-extra;
    padding: 40px 0;
    @media (max-width: base.$breakpoint-xxl) {
      max-width: 225px;
    }

    &_group {
      border-bottom: 1px solid base.$coffee-back;
      padding-left: 60px;
      transition: 0.4s ease-in-out;
      @media (max-width: base.$breakpoint-xxl) {
        padding-left: 20px;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: base.$white;
        cursor: pointer;
      }

      &:hover .admin__panel_category {
        color: base.$coffee-dark;
      }

      &_active {
        background-color: base.$white;

        .admin__panel_category {
          color: base.$coffee-dark;
        }
      }

      &_inner {
        padding-bottom: 15px;
      }

      a {
        text-decoration-line: none;
      }
    }

    &_category {
      font-size: 18px;
      font-weight: 700;
      color: base.$grey;
      margin: 0;
      text-transform: uppercase;
      padding: 15px 0;
      transition: 0.4s ease-in-out;
      @media (max-width: base.$breakpoint-xxl) {
        font-size: 14px;
      }
    }

    &_subcategory {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: base.$grey;
      margin: 5px 0 0;
      a {
        color: inherit;
      }
    }
  }

  &__main {
    max-width: 1273px;
    width: 100%;
    margin-left: 45px;
    padding: 40px 0;
    @media (max-width: base.$breakpoint-xxl) {
      margin-left: 15px;
    }

    &_title {
      font-size: 24px;
      font-weight: 700;
      color: base.$coffee-dark;
      text-transform: uppercase;
      margin: 0 0 0 20px;
      @media (max-width: base.$breakpoint-xxl) {
        font-size: 20px;
      }

      @media (max-width: base.$breakpoint-md) {
        font-size: 16px;
      }
    }
  }

  &__art {
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 40px;

    &_item {
      display: flex;
      align-items: center;
      background-color: base.$grey-light-extra;
      border-bottom: 1px solid base.$coffee-back;
      padding: 20px;
      @media (max-width: base.$breakpoint-xl) {
        padding: 15px;
        justify-content: space-between;
      }

      &_img {
        width: 110px;
        height: 60px;
        object-fit: cover;
      }

      &_name {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: base.$grey;
        text-transform: uppercase;
        margin: 0 0 0 40px;
        max-width: 130px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: base.$breakpoint-xl) {
          margin-left: 10px;
        }
      }

      &_date {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: base.$grey;
        margin: 0 0 0 40px;
        max-width: 80px;
        width: 100%;
        @media (max-width: base.$breakpoint-xxl) {
          margin-left: 10px;
        }
      }

      &_text {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        color: base.$grey;
        margin: 0 0 0 40px;
        max-width: 350px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: base.$breakpoint-xl) {
          margin-left: 10px;
          max-width: 300px;
        }
      }

      &_list {
        display: flex;
        align-items: center;
        margin-left: 40px;
        @media (max-width: base.$breakpoint-xl) {
          margin-left: 10px;
        }
      }

      &_btn {
        background: transparent;
        border: none;
        padding: 0;
        display: block;
        text-align: center;
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }

        p {
          font-size: 12px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: base.$grey;
          margin: 0;
          @media (max-width: base.$breakpoint-xxl) {
            font-size: 10px;
            font-weight: 600;
            letter-spacing: unset;
          }
        }
      }
    }
  }

  &__artist {
    margin-top: 20px;

    &_item {
      margin-bottom: 20px;
      border-bottom: 3px solid base.$grey-light-extra;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      &_block {
        .admin__art_item {
          background-color: base.$coffee-back;
        }
      }

      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: base.$grey-light-extra;
        padding: 16px 40px 16px 20px;
        border-radius: 5px;
        cursor: pointer;
      }

      &_button {
        border: none;
        background: none;

        &.active {
          transform: rotate(90deg);
        }
      }

      &_img {
        width: 78px;
        height: 78px;
        object-fit: cover;
        border-radius: 50%;
      }

      &_wrap {
        display: flex;
        align-items: center;
      }

      &_info {
        margin-left: 22px;
      }

      &_email {
        font-size: 16px;
        font-weight: 700;
        color: base.$grey;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        margin: 0;
      }

      &_name {
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        color: base.$grey;
        margin: 10px 0 0;
      }

      &_number {
        font-size: 14px;
        font-weight: 400;
        color: base.$grey;
        margin: 10px 0 0;
      }
    }

    &_location {
      padding: 20px;
      border-top: 1px solid base.$grey-light-normal;

      &_title {
        font-size: 14px;
        font-weight: 600;
        color: base.$black;
        text-transform: uppercase;
        margin: 0;
      }

      &_room {
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        color: base.$grey;
        letter-spacing: 0.02em;
        margin: 30px 0 0;
        display: flex;
        align-items: center;

        img {
          width: 14px;
          height: 16px;
          margin-right: 20px;
        }
      }

      &_address {
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        margin: 20px 0 0;
        color: base.$grey-brown;
        max-width: 160px;
        width: 100%;
      }

      &_text {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: base.$grey-normal;
        text-transform: uppercase;
        margin: 45px 0 0;
        max-width: 245px;
        width: 100%;
      }
    }
  }

  &__template {
    margin-left: 50px;

    &_btn {
      font-size: 14px;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      width: 100px;
      height: 50px;
      padding: 0;
      border: none;
      background-color: base.$coffee-main;
      color: base.$coffee-light;
      border-radius: 10px;
      margin-top: 20px;
      transition: 0.4s ease-in-out;

      &:hover {
        font-style: italic;
        background-color: base.$coffee-dark;
      }
    }

    .admin__main_title {
      margin-left: 0;
    }

    &_inner {
      margin-top: 30px;
    }

    &_style {
      display: flex;
      margin-top: 30px;

      &_item {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: base.$grey-light-normal;
        border: none;
        margin-right: 5px;
        text-transform: uppercase;

        &:last-child {
          margin-right: 0;
        }
      }

      &_h1 {
        font-size: 20px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_h2 {
        font-size: 18px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_h3 {
        font-size: 15px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_bold {
        font-size: 15px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_italic {
        font-size: 15px;
        font-style: italic;
        font-weight: 600;
        color: base.$coffee-dark;
        text-transform: uppercase;
      }

      &_brown {
        width: 24px;
        height: 24px;
        background-color: base.$coffee-dark;
        border-radius: 2px;
      }

      &_grey {
        width: 24px;
        height: 24px;
        background-color: base.$grey;
        border-radius: 2px;
      }
    }

    &_wrap {
      padding: 20px 30px;
      border-radius: 10px;
      height: 780px;
      overflow-y: scroll;
      max-width: 970px;
      width: 100%;
      background-color: base.$grey-light-normal;
      margin-top: 5px;

      &::-webkit-scrollbar-track {
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: base.$grey-ultra;
      }

      &::-webkit-scrollbar {
        width: 10px;
        background-color: base.$grey-ultra;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 2px solid base.$grey-ultra;
        background-color: base.$grey-normal-dark;
      }

      &_h1 {
        font-size: 20px;
        font-weight: 600;
        color: base.$coffee-dark;
        margin: 0;
      }

      &_h2 {
        font-size: 18px;
        font-weight: 600;
        color: base.$coffee-dark;
        margin: 0;
      }

      &_h3 {
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        color: base.$grey;

        &_brown {
          font-size: 15px;
          font-weight: 600;
          margin: 0;
          color: base.$coffee-dark;

          span {
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: base.$grey;
            border-radius: 50%;
            margin: 0 10px;
          }
        }

        &_bold {
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          color: base.$grey;
          margin: 0;
        }

        &_italic {
          font-size: 15px;
          line-height: 20px;
          font-style: italic;
          color: base.$grey;
          margin: 0;

          &_bold {
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
            font-style: italic;
            color: base.$coffee-dark;
            margin: 0;
          }
        }
      }

      &_p {
        font-size: 15px;
        line-height: 20px;
        color: base.$grey;
        margin: 0;
      }

      &_list {
        padding-left: 25px;
        margin: 0;

        li {
          font-size: 15px;
          line-height: 20px;
          color: base.$grey;
        }
      }
    }
  }

  &__faq {
    margin-top: 30px;

    &_controller {
      display: flex;
      align-items: center;

      &_error {
        font-size: 13px;
        font-weight: 400;
        color: base.$red;
        margin: 0 0 0 30px;
      }
    }

    &_item {
      border-bottom: 1px solid base.$grey-normal-extra;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &_delete {
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 900;
        color: base.$grey;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-left: 25px;
        padding: 0;

        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }

      &_save {
        width: 100px;
        height: 50px;
      }

      &_add {

        .admin__faq_group_question {
          .admin__faq_group_wrap {
            min-height: 50px;
          }
        }

        .admin__faq_group_wrap {
          min-height: 160px;
        }
      }
    }

    &_add {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 900;
      color: base.$grey;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin: 20px 0;
      padding: 0;

      img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }
    }

    &_group {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      &_error {
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        color: base.$red;
        align-self: center;
        margin: 0 0 0 30px;
      }

      &_active {
        .admin__faq_group_wrap {
          border: 1px solid base.$coffee-main-light;
        }
      }

      &_question {
        .admin__faq_group_wrap {
          font-weight: 600;
        }
      }

      &_title {
        font-size: 18px;
        font-weight: 600;
        color: base.$grey;
        max-width: 115px;
        width: 100%;
      }

      &_wrap {
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        color: base.$grey;
        background-color: base.$grey-light-normal;
        border: 1px solid base.$grey-light-normal;
        padding: 15px 30px;
        max-width: 670px;
        width: 100%;
        margin-left: 15px;
        border-radius: 10px;
        resize: none;
      }
    }

    &_block {
      max-width: 900px;
      width: 100%;
      margin-left: 40px;
      @media screen and (max-width: base.$breakpoint-lg) {
        margin-left: 10px;
      }

      .admin__main_title {
        margin-bottom: 30px;
        margin-left: 0;
      }

      &_item {
        margin-bottom: 20px;

        .admin__faq_block_item_button {
          border: none;
          background: none;
        }

        &_active {
          .admin__faq_block_question {
            background-color: base.$coffee-primary_light;
          }

          .admin__faq_block_question_text {
            color: base.$coffee-dark;
          }

          .admin__faq_block_item_closed {
            display: none;
          }

          .admin__faq_block_item_open {
            display: block;
          }
        }

        &_open {
          display: none;
        }
      }

      &_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: base.$grey-light-normal;
        padding: 15px 25px;
        border-radius: 5px;
        cursor: pointer;

        &_text {
          font-size: 15px;
          font-weight: 600;
          color: base.$grey;
          line-height: 19px;
          margin: 0 15px 0 0;
          max-width: 800px;
          width: 100%;
          @media screen and (max-width: base.$breakpoint-md) {
            font-size: 13px;
          }
        }
      }

      &_answer {
        padding: 15px 25px;

        &_text {
          font-size: 15px;
          font-weight: 400;
          color: base.$grey;
          line-height: 19px;
          margin: 0;
          @media screen and (max-width: base.$breakpoint-md) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.loading_box {
  height: 500px;
  display: grid;
  justify-content: center;
  align-items: center;
}
