@use "../../../../../../../styles/base";

.about_section {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: space-between;

  position: relative;
  overflow: hidden;

  z-index: 10;
}

.about_section__name {
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: base.$coffee-dark;
  text-transform: uppercase;
  padding: 1.5rem;
  position: absolute;
  left: 0;
  z-index: 5;

  @media screen and (max-width: base.$breakpoint-md) {
    font-size: 16px;
    padding: 1.5rem 0 1.5rem 15px;
    margin: 0;
  }
}

.about_section__wrapper {
  display: flex;
  padding: 5rem 150px;
  gap: 20px;

  font-size: 13px;
  font-weight: 400;

  @media screen and (max-width: base.$breakpoint-xl) {
    padding: 5rem 100px;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    padding: 1rem;
  }

  @media screen and (max-width: base.$breakpoint-lg) {
    display: grid;
  }

  flex: 2 1 auto; // child of flex item
}

.about_section__animated_back {
  @include base.section-animated-back;

  .about_section__shape_1 {
    width: 150px;
    position: absolute;

    bottom: 2rem;
    left: -4rem;

    @media screen and (max-width: base.$breakpoint-lg) {
      display: none;
    }
  }

  .about_section__shape_2 {
    width: 150px;
    position: absolute;

    top: 10rem;
    right: -2rem;

    @media screen and (max-width: base.$breakpoint-lg) {
      display: none;
    }
  }

  .about_section__shape_3 {
    width: 150px;
    position: absolute;

    top: 4rem;
    right: -2rem;

    @media screen and (max-width: base.$breakpoint-lg) {
      display: none;
    }
  }

  .about_section__shape_art {
    width: 200px;
    height: 65px;
    position: absolute;

    left: 20%;
    top: 5rem;

    @media screen and (max-width: base.$breakpoint-md) {
      left: 0;
      top: 15rem;
    }
  }

  .about_section__shape_in {
    width: 250px;
    height: 65px;
    position: absolute;

    left: 35%;
    top: 12rem;

    @media screen and (max-width: base.$breakpoint-md) {
      top: 25rem;
    }
  }

  .about_section__shape_space {
    width: 300px;
    height: 120px;
    position: absolute;

    left: 55%;
    top: 27rem;

    @media screen and (max-width: base.$breakpoint-md) {
      left: 35%;
      top: 40rem;
    }
  }
}
