@use "../../../../../../../../styles/base";

.second_sub_section {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;

  z-index: 100;
}

.second_sub_section__image_wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media screen and (max-width: base.$breakpoint-md) {
    display: none;
  }
}

.second_sub_section__content_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  .second_sub_section__image_box {
    align-items: flex-end;
  }
}

.second_sub_section__content {
  display: grid;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
}

.second_sub_section__text_box {
  width: 100%;
  max-width: 400px;
}

.second_sub_section__sub_title {
  width: 100%;
  max-width: 300px;

  font-size: 16px;
  font-weight: 600;
  color: base.$coffee-dark;
}

.second_sub_section__image_box {
  display: flex;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @media screen and (max-width: base.$breakpoint-md) {
      max-width: 143px;
      max-height: 206px;
    }
  }

  span {
    writing-mode: vertical-rl;
    font-size: 10px;
    font-weight: bold;
    font-style: italic;
  }
}

.image_box_1 {
  z-index: 10;
  align-items: flex-end;

  span {
    transform: rotate(180deg);
  }
}

.image_box_2 {
  bottom: 2.5rem;
  left: -2rem;

  @media screen and (max-width: base.$breakpoint-lg) {
    bottom: 2rem;
  }
}

.image_box_3 {
  bottom: 1rem;
  right: 3.5rem;
  align-items: flex-start;

  @media screen and (min-width: base.$breakpoint-md) {
    span {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: base.$breakpoint-lg) {
    right: 2.5rem;
  }
}

.image_box_4 {
  min-width: 200px;
  max-height: 300px;

  @media screen and (min-width: base.$breakpoint-md) {
    span {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: base.$breakpoint-md) {
    display: none;
  }
}

.second_sub_section__mobile_image_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  .image_box_3 {
    display: grid;
    align-items: flex-end;
    right: -1rem;
    bottom: 2rem;

    span {
      writing-mode: horizontal-tb;
    }
  }

  .image_box_4 {
    display: grid;
    left: -2rem;

    span {
      writing-mode: horizontal-tb;
      text-align: right;
    }
  }

  @media screen and (min-width: base.$breakpoint-md) {
    display: none;
  }
}

.image_4 {
  max-width: 255px !important;
  max-height: 200px !important;
}
