// ===-COLORS-===
/* Neutral */
$black: #000000;
$white: #ffffff;

/* Grey */
$grey: #504a48;
$grey-ultra: #c7c2bf;
$grey-ultra-normal: #CDCDCD;
$grey-ultra-light: #E5E8EB;
$grey-ultra-extra: #ACACAC;
$grey-light: #979493;
$grey-normal: #ADA4A0;
$grey-normal-back: #ECE9E7;
$grey-normal-extra: #BAB6B5;
$grey-light-extra: #e3deda;
$grey-extra-light: #D9D9D9;
$grey-light-normal: #E4DEDA;
$grey-extra: #CFDBD5;
$grey-dark: #332C29;
$grey-normal-dark: #7d7b7a;
$grey-primary: #B4B0AE;
$grey-primary-light: #e9e4e0;
$grey-brown: #6D6663;
$grey-brown-light: #6F5E59;
$grey-main: #5D5D5B;
$grey-darker: #333333;

/* Coffee */
$coffee-main: #c57247;
$coffee-main-light: #EE6C40;
$coffee-light-extra: #E6D8CD;
$coffee-light: #f7dbc0;
$coffee-normal: #e1a16a;
$coffee-dark: #712e19;
$coffee-back: #f1ece8;
$coffee-primary: #D68266;
$coffee-primary-extra: #DFCFC4;
$coffee-primary_light: #E2D2CD;

/* Blue */
$blue-primary: #2196f3;
$blue: #001AFF;
$blue-dark: #0010A2;
$blue-ocean: #268595;

/* Red */
$red: #DE0003;
$red-primary: #FF0000;
$red-light: #FF7071;
$red-extra-light: #D66D66;
$red-light-ultra: #F1DFDE;
$red-dark: #A90101;

/* Green */
$green: #00a976;
$green-dark: #00592E;
$green-light: #0CA36C;

/* Purple */
$purple: #B700F8;
$purple-light: #A566D6;
$purple-violet: #8F00FF;
$purple-extra: #A300DC;
$purple-violet-light: #B0A3FF;
$purple-violet-extra: #E5E7FB;

$violet: #7A52F4;
$violet-light: #e4dcfd;

/* Yellow */
$yellow-orange: #F89500;

$popup-dark-background: rgba(0, 0, 0, 0.8);
$popup-light-background: rgba(255, 255, 255, 0.8);

// ===-SCREEN BREAKPOINTS-===
/* Mobile */
$breakpoint-xs: 320px;
$breakpoint-sm: 425px;
/* Tablets */
$breakpoint-md: 768px;
/* Laptops */
$breakpoint-lg: 1024px;
$breakpoint-lg-big: 1200px;
/* Desktops */
$breakpoint-xl: 1366px;
$breakpoint-xxl: 1605px;
$breakpoint-2xl: 1680px;
$breakpoint-fhd: 1920px;
$breakpoint-3xl: 2560px;
$breakpoint-4xl: 3840px;

$body: calc(100vh - 292.8px);
$editorBody: calc(100vh - 200px);

// ===-MIXINS-===
@mixin section-wrapper {
  position: relative;
  overflow: hidden;
}

@mixin section-animated-back {
  position: absolute;
  inset: 0;
  z-index: 0;
}
