@use '@styles/base';

.privacy {
  min-height: calc(100vh - 292px);
  background-color: base.$coffee-back;
  padding: 40px;

  @media (max-width: base.$breakpoint-md) {
    padding: 25px;
  }

  &__content {
    margin-left: 25px;
    max-width: 1680px;
    width: 100%;
    word-break: break-word;
  }
}
