@use '../../../../styles/base';

.root {
  width: 100%;

  margin: auto;
}

.main {
  width: 100%;
  margin: 0 auto;
  background-color: #f1ece8;
  display: grid;
  gap: 50px;
  @media screen and (max-width: base.$breakpoint-md) {
    gap: 0;
  }
}
