@use '@styles/base';
@use '@styles/main';

.admin {
  min-height: calc(100vh - 292px);
  background-color: base.$coffee-back;

  &__block {
    display: flex;
    align-items: stretch;
    padding-right: 40px;
  }

  &__panel {
    max-width: 320px;
    width: 100%;
    background-color: base.$grey-light-extra;
    padding: 40px 0;
    @media (max-width: base.$breakpoint-xxl) {
      max-width: 225px;
    }

    a {
      text-decoration-line: none !important;
    }

    &_group {
      border-bottom: 1px solid base.$coffee-back;
      padding-left: 60px;
      transition: 0.4s ease-in-out;
      @media (max-width: base.$breakpoint-xxl) {
        padding-left: 20px;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: base.$white;
        cursor: pointer;
      }

      &:hover .admin__panel_category {
        color: base.$coffee-dark;
      }

      &_active {
        background-color: base.$white;

        .admin__panel_category {
          color: base.$coffee-dark;
        }
      }

      &_inner {
        padding-bottom: 15px;
      }

      a {
        text-decoration-line: none;
      }
    }

    &_category {
      font-size: 18px;
      font-weight: 700;
      color: base.$grey;
      margin: 0;
      text-transform: uppercase;
      padding: 15px 0;
      transition: 0.4s ease-in-out;
      @media (max-width: base.$breakpoint-xxl) {
        font-size: 14px;
      }
    }

    &_subcategory {
      font-size: 13px;
      display: block;
      text-decoration: none;
      line-height: 18px;
      font-weight: 600;
      color: base.$grey;
      margin: 5px 0 0;
      transition: 0.4s ease-in-out;

      &:hover {
        color: base.$black;
      }

      &_link {
        color: inherit;
      }

      &_link:visited {
        color: inherit;
      }

      &_link:link {
        color: inherit;
      }
    }
  }

  &__main {
    width: 100%;
    max-width: 2700px;
    margin-left: 45px;
    padding: 40px 0;

    @media screen and (max-width: base.$breakpoint-3xl) {
      max-width: 1800px;
    }

    @media screen and (max-width: base.$breakpoint-fhd) {
      max-width: 1300px;
    }

    @media (max-width: base.$breakpoint-xxl) {
      margin-left: 15px;
    }

    &_default {
      margin-left: 20px;
      margin-top: 70px;

      &_title {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        color: base.$grey-normal;
        text-transform: uppercase;
        margin: 0;

        span {
          color: base.$coffee-dark;
          display: block;
        }
      }

      &_subtitle {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        color: base.$grey-normal;
        text-transform: uppercase;
        margin: 30px 0 20px;
      }

      &_btn {
        margin-top: 30px;
        width: 110px;
      }

      &_artist {
        margin-top: 75px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 375px;
          background: linear-gradient(180deg, rgba(241, 236, 232, 0) 0%, #f1ece8 90.62%);
          bottom: 0;
        }

        .admin__main_title {
          margin-left: 0;
        }

        .admin__artist_item {
          border-bottom: none;
        }

        .admin__artist_item_top {
          background-color: base.$grey-primary-light;
        }

        .admin__artist_item_img {
          background-color: #c8c1bd;
        }

        .admin__artist_item_email {
          background-color: #c8c1bd;
          width: 250px;
          height: 20px;
          border-radius: 5px;
        }

        .admin__artist_item_name {
          background-color: #c8c1bd;
          width: 130px;
          height: 15px;
          border-radius: 5px;
        }

        .admin__artist_item_number {
          background-color: #c8c1bd;
          width: 100px;
          height: 15px;
          border-radius: 5px;
        }
      }
    }

    &_title {
      font-size: 24px;
      font-weight: 700;
      color: base.$coffee-dark;
      text-transform: uppercase;
      margin: 0 0 0 20px;
      @media (max-width: base.$breakpoint-xxl) {
        font-size: 20px;
      }
    }
  }

  &__art {
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 40px;

    &_item {
      display: flex;
      align-items: center;
      background-color: base.$grey-light-extra;
      border-bottom: 1px solid base.$coffee-back;
      padding: 20px;
      justify-content: space-between;

      @media (max-width: base.$breakpoint-xl) {
        padding: 15px;
      }

      &_img {
        width: 110px;
        height: 60px;
        object-fit: cover;
        @media (max-width: base.$breakpoint-lg-big) {
          width: 80px;
          height: 55px;
        }
      }

      &_name {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: base.$grey;
        text-transform: uppercase;
        margin: 0 0 0 40px;
        max-width: 130px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: base.$breakpoint-xl) {
          margin-left: 10px;
          max-width: 100px;
        }
      }

      &_date {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: base.$grey;
        margin: 0 0 0 40px;
        max-width: 80px;
        width: 100%;
        @media (max-width: base.$breakpoint-xxl) {
          margin-left: 10px;
        }
      }

      &_text {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        color: base.$grey;
        margin: 0 0 0 40px;
        max-width: 350px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: base.$breakpoint-xl) {
          margin-left: 10px;
          max-width: 230px;
        }
        @media (max-width: base.$breakpoint-lg-big) {
          max-width: 180px;
        }
      }

      &_list {
        display: flex;
        margin-left: 40px;
        align-items: flex-end;
        @media (max-width: base.$breakpoint-xl) {
          margin-left: 10px;
        }
      }

      &_btn {
        background: transparent;
        border: none;
        padding: 0;
        display: block;
        text-align: center;
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }

        p {
          font-size: 12px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: base.$grey;
          margin: 0;
          @media (max-width: base.$breakpoint-xxl) {
            font-size: 10px;
            font-weight: 600;
            letter-spacing: unset;
          }
        }
      }
    }
  }

  &__artist {
    margin-top: 20px;

    &_item {
      margin-bottom: 20px;
      border-bottom: 3px solid base.$grey-light-extra;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      &_block {
        .admin__art_item {
          background-color: base.$coffee-back;
        }
      }

      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: base.$grey-light-extra;
        padding: 16px 40px 16px 20px;
        border-radius: 5px;
        cursor: pointer;
      }

      &_button {
        border: none;
        background: none;

        &.active {
          transform: rotate(90deg);
        }
      }

      &_img {
        width: 78px;
        height: 78px;
        object-fit: cover;
        border-radius: 50%;
      }

      &_wrap {
        display: flex;
        align-items: center;
      }

      &_info {
        margin-left: 22px;
      }

      &_email {
        font-size: 16px;
        font-weight: 700;
        color: base.$grey;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        margin: 0;
      }

      &_name {
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        color: base.$grey;
        margin: 10px 0 0;
      }

      &_number {
        font-size: 14px;
        font-weight: 400;
        color: base.$grey;
        margin: 10px 0 0;
      }
    }

    &_location {
      padding: 20px;
      border-top: 1px solid base.$grey-light-normal;

      &_title {
        font-size: 14px;
        font-weight: 600;
        color: base.$black;
        text-transform: uppercase;
        margin: 0;
      }

      &_room {
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        color: base.$grey;
        letter-spacing: 0.02em;
        margin: 30px 0 0;
        display: flex;
        align-items: center;

        img {
          width: 14px;
          height: 16px;
          margin-right: 20px;
        }
      }

      &_address {
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        margin: 20px 0 0;
        color: base.$grey-brown;
        max-width: 160px;
        width: 100%;
      }

      &_text {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: base.$grey-normal;
        text-transform: uppercase;
        margin: 45px 0 0;
        max-width: 245px;
        width: 100%;
      }
    }
  }

  &__template {
    margin-left: 50px;

    &_room {
      margin-top: 30px;

      .admin__faq_group_input {
        &::-webkit-input-placeholder {
          font-size: 15px;
          font-weight: 700;
          color: base.$grey;
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }

        &::-moz-placeholder {
          font-size: 15px;
          font-weight: 700;
          color: base.$grey;
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }

        &:-ms-input-placeholder {
          font-size: 15px;
          font-weight: 700;
          color: base.$grey;
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }

        &:-moz-placeholder {
          font-size: 15px;
          font-weight: 700;
          color: base.$grey;
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }
      }
    }

    &_btn {
      font-size: 14px;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      width: 100px;
      height: 50px;
      padding: 0;
      border: none;
      background-color: base.$coffee-main;
      color: base.$coffee-light;
      border-radius: 10px;
      margin-top: 20px;
      transition: 0.4s ease-in-out;

      &:hover {
        font-style: italic;
        background-color: base.$coffee-dark;
      }
    }

    .admin__main_title {
      margin-left: 0;
    }

    &_inner {
      margin-top: 30px;
    }

    &_style {
      display: flex;
      margin-top: 30px;

      &_item {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: base.$grey-light-normal;
        border: none;
        margin-right: 5px;
        text-transform: uppercase;

        &:last-child {
          margin-right: 0;
        }
      }

      &_h1 {
        font-size: 20px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_h2 {
        font-size: 18px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_h3 {
        font-size: 15px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_bold {
        font-size: 15px;
        font-weight: 600;
        color: base.$coffee-dark;
      }

      &_italic {
        font-size: 15px;
        font-style: italic;
        font-weight: 600;
        color: base.$coffee-dark;
        text-transform: uppercase;
      }

      &_brown {
        width: 24px;
        height: 24px;
        background-color: base.$coffee-dark;
        border-radius: 2px;
      }

      &_grey {
        width: 24px;
        height: 24px;
        background-color: base.$grey;
        border-radius: 2px;
      }
    }

    &_wrap {
      padding: 20px 30px;
      border-radius: 10px;
      height: 780px;
      overflow-y: scroll;
      max-width: 2200px;
      width: 100%;
      background-color: base.$grey-light-normal;
      margin-top: 5px;

      @media screen and (max-width: base.$breakpoint-3xl) {
        max-width: 1700px;
      }

      @media screen and (max-width: base.$breakpoint-fhd) {
        max-width: 970px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: base.$grey-ultra;
      }

      &::-webkit-scrollbar {
        width: 10px;
        background-color: base.$grey-ultra;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 2px solid base.$grey-ultra;
        background-color: base.$grey-normal-dark;
      }

      &_h1 {
        font-size: 20px;
        font-weight: 600;
        color: base.$coffee-dark;
        margin: 0;
      }

      &_h2 {
        font-size: 18px;
        font-weight: 600;
        color: base.$coffee-dark;
        margin: 0;
      }

      &_h3 {
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        color: base.$grey;

        &_brown {
          font-size: 15px;
          font-weight: 600;
          margin: 0;
          color: base.$coffee-dark;

          span {
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: base.$grey;
            border-radius: 50%;
            margin: 0 10px;
          }
        }

        &_bold {
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          color: base.$grey;
          margin: 0;
        }

        &_italic {
          font-size: 15px;
          line-height: 20px;
          font-style: italic;
          color: base.$grey;
          margin: 0;

          &_bold {
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
            font-style: italic;
            color: base.$coffee-dark;
            margin: 0;
          }
        }
      }

      &_p {
        font-size: 15px;
        line-height: 20px;
        color: base.$grey;
        margin: 0;
      }

      &_list {
        padding-left: 25px;
        margin: 0;

        li {
          font-size: 15px;
          line-height: 20px;
          color: base.$grey;
        }
      }
    }
  }

  &__faq {
    margin-top: 30px;
    min-height: 495px;

    &_controller {
      display: flex;
      align-items: center;

      &_error {
        font-size: 13px;
        font-weight: 400;
        color: base.$red;
        margin: 0 0 0 30px;
      }
    }

    &_list {
      display: flex;
      margin-left: 15px;
      max-width: 1400px;
      flex-wrap: wrap;
      gap: 20px;

      @media screen and (max-width: base.$breakpoint-3xl) {
        max-width: 900px;
      }

      @media screen and (max-width: base.$breakpoint-fhd) {
        max-width: 670px;
      }

      &_back {
        background-color: base.$grey-light-normal;
        padding: 15px;
        border-radius: 10px;
      }

      &_item {
        width: 112px;
        position: relative;

        &_img {
          width: 112px;
          height: 112px;
          border-radius: 10px;
          object-fit: cover;
          border: 1px dashed rgba(108, 102, 99, 0.6);
        }

        &_delete {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 3px;
          right: 3px;
          background: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 5;
        }

        &_name {
          width: 100px;
          height: 15px;
          font-size: 13px;
          color: base.$grey;
          margin: 0;
          overflow: hidden;
        }
      }
    }

    &_images {
      width: 1400px;
      background-color: base.$grey-light-normal;
      border-radius: 10px;
      text-align: center;
      padding: 45px;
      margin-left: 15px;

      @media screen and (max-width: base.$breakpoint-3xl) {
        width: 900px;
      }

      @media screen and (max-width: base.$breakpoint-fhd) {
        width: 670px;
      }

      &_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: base.$coffee-main;
        width: 185px;
        margin: 0 0 0 15px;
        text-align: left;
      }

      &_text {
        font-size: 15px;
        font-weight: 600;
        color: base.$black;
        margin: 30px 0;
      }

      &_btn {
        padding: 0 15px;
      }
    }

    &_item {
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;

      &:after {
        display: block;
        content: '';
        width: 100%;
        max-width: 800px;
        height: 1px;
        background-color: base.$grey-normal-extra;
        bottom: 0;
        position: absolute;
      }

      &_main {
        .admin__faq_group_input_main {
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
          color: base.$coffee-dark;
        }
      }

      &_editor {
        &:after {
          display: none;
        }

        .admin__faq_group_input_ip {
          background-color: transparent;
          border-color: transparent;

          &::-webkit-input-placeholder {
            font-weight: 400;
          }

          &::-moz-placeholder {
            font-weight: 400;
          }

          &:-ms-input-placeholder {
            font-weight: 400;
          }

          &:-moz-placeholder {
            font-weight: 400;
          }
        }
      }

      &_more {
        margin-top: 30px;
      }

      &_delete {
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 900;
        color: base.$grey;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-left: 25px;
        padding: 0;

        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }

      &_save {
        width: 100px;
        height: 50px;
      }

      &_add {
        .admin__faq_group_question {
          .admin__faq_group_wrap {
            min-height: 50px;
          }
        }

        .admin__faq_group_wrap {
          min-height: 160px;
        }
      }
    }

    &_add {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 900;
      color: base.$grey;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin: 20px 0;
      padding: 0;

      img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }
    }

    &_group {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      &_double {
        width: 100%;
      }

      &_item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        .admin__faq_group_error {
          margin-top: 15px;
        }
      }

      &_wrapper {
        max-width: 1400px;
        width: 100%;

        @media screen and (max-width: base.$breakpoint-3xl) {
          max-width: 900px;
        }

        @media screen and (max-width: base.$breakpoint-fhd) {
          max-width: 670px;
        }
      }

      &_top {
        .admin__faq_group_title {
          margin: 0;
        }
      }

      &_label {
        display: block;
        font-size: 13px;
        font-weight: 700;
        color: base.$grey;
        margin-left: 15px;
        margin-bottom: 5px;
      }

      &_error {
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        color: base.$red;
        align-self: center;
        margin: 0 0 0 30px;
      }

      &_active {
        .admin__faq_group_wrap {
          border: 1px solid base.$coffee-main-light;
        }
      }

      &_question {
        .admin__faq_group_wrap {
          font-weight: 600;
        }
      }

      &_title {
        font-size: 18px;
        font-weight: 600;
        color: base.$grey;
        max-width: 115px;
        width: 100%;
      }

      &_wrap {
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        color: base.$grey;
        background-color: base.$grey-light-normal;
        border: 1px solid base.$grey-light-normal;
        padding: 15px 30px;
        max-width: 1400px;
        width: 100%;
        margin-left: 15px;
        border-radius: 10px;
        resize: none;

        @media screen and (max-width: base.$breakpoint-3xl) {
          max-width: 900px;
        }

        @media screen and (max-width: base.$breakpoint-fhd) {
          max-width: 670px;
        }
      }

      &_input {
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        color: base.$grey;
        background-color: base.$grey-light-normal;
        border: 1px solid base.$grey-light-normal;
        padding: 15px 30px;
        max-width: 1400px;
        width: 100%;
        height: 55px;
        margin-left: 15px;
        border-radius: 10px;
        resize: none;

        &::-webkit-scrollbar {
          display: none;
        }

        @media screen and (max-width: base.$breakpoint-3xl) {
          max-width: 900px;
        }

        @media screen and (max-width: base.$breakpoint-fhd) {
          max-width: 670px;
        }
      }

      &_area {
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        color: base.$grey;
        background-color: base.$grey-light-normal;
        border: 1px solid base.$grey-light-normal;
        padding: 15px 30px;
        max-width: 1400px;
        width: 100%;
        height: 150px;
        margin-left: 15px;
        border-radius: 10px;
        resize: none;

        @media screen and (max-width: base.$breakpoint-3xl) {
          max-width: 900px;
        }

        @media screen and (max-width: base.$breakpoint-fhd) {
          max-width: 670px;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &_block {
      max-width: 900px;
      width: 100%;
      margin-left: 40px;

      .admin__main_title {
        margin-bottom: 30px;
        margin-left: 0;
      }

      &_item {
        margin-bottom: 20px;

        &_active {
          .admin__faq_block_question {
            background-color: base.$coffee-primary_light;
          }

          .admin__faq_block_question_text {
            color: base.$coffee-dark;
          }

          .admin__faq_block_item_closed {
            display: none;
          }

          .admin__faq_block_item_open {
            display: block;
          }
        }

        &_open {
          display: none;
        }
      }

      &_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: base.$grey-light-normal;
        padding: 15px 25px;
        border-radius: 5px;
        cursor: pointer;

        &_text {
          font-size: 15px;
          font-weight: 600;
          color: base.$grey;
          line-height: 19px;
          margin: 0 15px 0 0;
          max-width: 800px;
          width: 100%;
        }
      }

      &_answer {
        padding: 15px 25px;

        &_text {
          font-size: 15px;
          font-weight: 400;
          color: base.$grey;
          line-height: 19px;
          margin: 0;
        }
      }
    }
  }
}

.loading_box {
  width: 400px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.room {
  min-height: calc(100vh - 292px);
  padding-bottom: 40px;
  background-color: base.$coffee-back;

  &__block {
    display: flex;
    @media screen and (max-width: base.$breakpoint-md) {
      flex-direction: column;
    }

    &_wrap {
      position: relative;
      max-width: 765px;
      width: 100%;

      &_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &_text {
        font-size: 28px;
        font-weight: 700;
        color: base.$white;
        text-transform: uppercase;
        margin: 0;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &_info {
      width: 100%;
      padding: 90px;
      background-color: base.$grey-light-normal;
      margin: 35px 0;
      @media screen and (max-width: base.$breakpoint-lg) {
        padding: 40px;
      }
      @media screen and (max-width: base.$breakpoint-md) {
        padding: 30px 45px;
        margin: 0;
      }

      &_title {
        font-size: 34px;
        color: base.$coffee-dark;
        text-transform: uppercase;
        margin: 0;
        @media screen and (max-width: base.$breakpoint-lg) {
          font-size: 23px;
        }
        @media screen and (max-width: base.$breakpoint-md) {
          font-size: 20px;
        }
      }

      &_text {
        font-size: 15px;
        line-height: 20px;
        color: base.$grey;
        margin: 20px 0 0;
      }

      @media screen and (max-width: base.$breakpoint-xs) {
        &_title {
          font-size: 18px;
        }

        &_text {
          font-size: 13px;
        }
      }
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    justify-content: space-between;
    @media screen and (max-width: base.$breakpoint-md) {
      margin-top: 0;
    }

    &_item {
      max-width: 600px;
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: base.$breakpoint-md) {
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 15px;
      }

      &_title {
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        color: base.$coffee-dark;
        margin: 0;
        @media screen and (max-width: base.$breakpoint-md) {
          font-size: 18px;
        }
      }

      &_text {
        font-size: 15px;
        line-height: 19px;
        font-weight: 400;
        color: base.$grey;
        margin: 20px 0 0;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_room {
        font-size: 13px;
        font-weight: 700;
        color: base.$grey;
        letter-spacing: 0.02em;
        display: flex;
        margin: 30px 0 0;
        max-width: 300px;

        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }

      &_address {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: base.$grey;
        margin: 20px 0 0;
        width: 160px;
      }
    }

    @media screen and (max-width: base.$breakpoint-md) {
      display: grid;
    }
  }

  &__slider_root {
    width: 100%;
    padding: 0 30px;
    overflow: hidden;
    position: relative;

    &_arrows_box {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button:first-of-type {
        transform: rotateZ(-180deg);
      }

      button {
        background: none;
        border: none;
        z-index: 100;
      }
    }
  }

  &__slider {
    width: 100%;
    height: 300px;
    overflow: hidden;

    &_item {
      width: 100%;
      height: 300px;
      padding: 0 10px;
      cursor: pointer;

      &_img {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: cover;
      }
    }
  }
}

.edit_area {
  background: none;
  width: 100%;
  height: 100%;
  resize: none;
  border: none;

  font-family: 'Sofia Pro', sans-serif;
  font-size: 18px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.file_reject_error {
  position: absolute;
  inset: 0;
  background-color: rgba(228, 222, 218, 0.5);

  display: flex;
  justify-content: center;
  padding-bottom: 20px;

  img {
    position: absolute;
    left: 40%;
    top: 40%;
    transform: translateX(-50%);
    transform: translateY(-50%);
  }
}

.rejected_image_box {
  border-color: base.$red;
  border-style: solid;
}

.rejected_image_name {
  color: base.$red;
}

.errored_input {
  border-color: base.$coffee-main-light;
}

.additional_upload_box {
  text-align: center;
  padding: 5px;
}

.upload_image {
  width: 112px;
  height: 112px;
}
.modal {
  position: fixed;
  inset: 0;
  z-index: 100000;
  background-color: base.$popup-dark-background;
  img {
    padding: 20px;
    padding-top: 100px;
    position: absolute;
    inset: 0;
    width: 80vw;
    max-width: 2000px;
    margin: 0 auto;

  }
}
