@use "../../../../../../../styles/base";

.programming {
  overflow: hidden;
  padding: 30px 0;
  position: relative;
  min-height: 75vh;

  @media (max-width: base.$breakpoint-md) {
    padding: 0 0 15px;
  }

  &__content_wrapper {
    padding: 40px;
    @media (max-width: base.$breakpoint-md) {
      padding-top: 0;
    }
  }

  &__block {
    padding-left: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5%;
    position: relative;
    z-index: 1000;

    @media screen and (max-width: base.$breakpoint-lg) {
      display: grid;
    }

    @media (max-width: base.$breakpoint-md) {
      padding-left: 15px;
    }

    &__content {
      min-width: 300px;
      max-width: 400px;
      margin-top: 200px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: base.$breakpoint-lg) {
        margin-top: 10px;
        width: 100%;
        max-width: 100%;
        min-width: 0;
      }

      @media screen and (max-width: base.$breakpoint-md) {
        margin-top: 0;
      }

      .content__title {
        font-size: 22px;
        font-weight: 700;
        color: base.$coffee-dark;
        text-transform: uppercase;
      }

      .content__description {
        margin: 0;
        font-size: 13px;
      }

      .content__info {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 600;
        color: base.$coffee-dark;
      }
    }

    &__image_box {
      img {
        object-fit: cover;
        object-position: center;
      }

      .first_box {
        display: flex;
        align-items: flex-start;
        gap: 5rem;
        position: relative;

        @media screen and (max-width: base.$breakpoint-md) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 100%;
          margin-bottom: 10px;
        }

        .circle_shape {
          display: inline;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          position: absolute;
          top: 1rem;
          left: -5rem;
          z-index: 1500;

          @media screen and (max-width: base.$breakpoint-lg) {
            display: none;
          }
        }

        .img_1 {
          width: 300px;
          height: 200px;
          display: inline;

          @media screen and (max-width: base.$breakpoint-xl) {
            width: 250px;
            height: 150px;
          }

          @media screen and (max-width: base.$breakpoint-md) {
            width: 100% !important;
            position: static;
            margin: auto;
            height: 200px !important;
          }
        }

        .img_2 {
          width: 320px;
          height: 350px;
          margin-top: 20px;
          display: inline;

          @media screen and (max-width: base.$breakpoint-md) {
            width: 100% !important;
            position: static;
            margin: auto;
            height: 200px !important;
          }

          @media screen and (max-width: base.$breakpoint-xl) {
            width: 290px;
            height: 320px;
          }
        }
      }

      .second_box {
        display: block;

        @media screen and (max-width: base.$breakpoint-md) {
          display: flex;
          justify-content: center;
        }

        .rect_shape {
          display: inline;
          width: 160px;
          height: 250px;
          position: relative;
          left: 2.5rem;
          bottom: 2.5rem;
          z-index: 1500;

          @media screen and (max-width: base.$breakpoint-lg) {
            display: none;
          }
        }

        .img_3 {
          width: 390px;
          height: 250px;
          position: relative;
          right: 1rem;
          bottom: 2rem;
          display: inline;

          @media screen and (max-width: base.$breakpoint-md) {
            width: 100% !important;
            position: static;
            margin: auto;
            height: 200px !important;
          }

          @media screen and (max-width: base.$breakpoint-xl) {
            width: 350px;
            height: 200px;
            right: 2rem;
            bottom: 5rem;
          }
        }
      }
    }
  }
}

.programming_section__animated_back {
  @include base.section-animated-back;

  .programming_section__shape_1 {
    width: 150px;

    position: absolute;
    top: 150px;
    right: 30px;
    z-index: 10;

    @media screen and (max-width: base.$breakpoint-md) {
      display: none;
    }
  }

  .programming_section__shape_2 {
    width: 150px;

    position: absolute;
    top: 80px;
    right: 0;

    @media screen and (max-width: base.$breakpoint-md) {
      display: none;
    }
  }
}

.programming_section__name {
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: base.$coffee-dark;
  text-transform: uppercase;
  left: 0;
  margin-top: 35px;
  position: absolute;

  @media screen and (max-width: base.$breakpoint-lg) {
    font-size: 16px;
    padding: 1.5rem;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    padding: 3rem 0;
  }
}
