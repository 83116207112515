@use "../../../../styles/base";
@use "../../../../styles/main";

.notfound {
  min-height: calc(100vh - 292px);

  &__block {
    text-align: center;
    padding: 95px 15px;

    &_img {
      max-width: 520px;
      width: 100%;
      margin-bottom: 75px;
    }

    &_text {
      font-size: 17px;
      font-weight: 400;
      color: base.$grey;
      margin: 10px 0 0;
    }

    &_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100px;
      padding: 0 15px;
      margin: 35px auto 0;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
