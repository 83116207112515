@use '../../../../../../styles/base';

.root {
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  inset: 0;
  z-index: 1000;

  &:hover {
    .button_wrapper__button {
      opacity: 1;
    }
  }
}

.button_wrapper__button {
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity .2s ease-in-out;

  svg {
    transition: all .2s ease-in-out;
  }

  &:hover {
    svg {
      fill: base.$blue-primary;
      transform: scale(1.1);
    }
  }
}

.hidden_button {
  opacity: 0;
}
