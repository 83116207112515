@use '@styles/base';

.footer {
  padding: 40px;
  background-color: base.$coffee-light;
  @media (max-width: base.$breakpoint-lg) {
    padding: 30px 25px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    @media (max-width: base.$breakpoint-lg) {
      flex-direction: column-reverse;
    }

    &_logo {
      width: 159px;
      height: 33px;
    }

    &_link {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: base.$grey;
      text-decoration: underline;
      margin: 20px 0;
      @media (max-width: base.$breakpoint-lg) {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    &_text {
      font-size: 15px;
      color: base.$grey;
      margin: 0;
    }

    &_left {
      max-width: 600px;
      width: 100%;
      @media (max-width: base.$breakpoint-lg) {
        margin-top: 20px;
      }
    }

    &_right {
      display: flex;
      max-width: 645px;
      width: 100%;
      margin-left: 15px;
      @media (max-width: base.$breakpoint-lg) {
        flex-direction: column;
        margin-left: 0;
      }
    }

    &_bottom {
      display: flex;
      align-items: center;
      @media (max-width: base.$breakpoint-md) {
        align-items: flex-start;
        flex-direction: column;
      }

      .footer__content_link {
        margin: 0 0 0 15px;
        @media (max-width: base.$breakpoint-md) {
          margin: 20px 0 0;
        }
      }
    }

    &_info {
      max-width: 510px;
      width: 100%;
      margin-left: 25px;
      @media (max-width: base.$breakpoint-lg) {
        margin-left: 0;
      }
    }

    &_title {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.02em;
      color: base.$coffee-dark;
      text-transform: uppercase;
      margin: 0;
      @media (max-width: base.$breakpoint-lg) {
        font-size: 20px;
      }
    }

    &_address {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      color: base.$grey;
      margin: 0;
      @media (max-width: base.$breakpoint-lg) {
        margin-top: 20px;
      }
    }

    &_phone {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: base.$grey;
      margin-top: 30px;
      text-decoration: none;
      @media (max-width: base.$breakpoint-lg) {
        margin-top: 20px;
      }
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    @media (max-width: base.$breakpoint-lg) {
      flex-direction: column;
      margin-top: 20px;
    }

    &_item {
      display: flex;
      align-items: center;
      @media (max-width: base.$breakpoint-lg) {
        margin-bottom: 15px;
      }
    }

    &_icon {
      width: 29px;
      height: 29px;
    }

    &_text {
      font-size: 15px;
      font-weight: 500;
      color: base.$grey;
      display: inline-block;
      margin-left: 18px;
    }
  }
}
