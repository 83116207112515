@use "../../../../../../../../styles/base";

.first_sub_section {
  display: grid;

  width: 100%;
  max-width: 400px;
  z-index: 100;

  @media screen and (max-width: base.$breakpoint-lg) {
    max-width: 100%;
  }
}

.first_sub_section__title {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  color: base.$coffee-dark;
}

.first_sub_section__sub_title {
  width: 100%;
  max-width: 250px;

  font-size: 16px;
  font-weight: 600;
  color: base.$coffee-dark;
  margin-top: 80px;
  position: absolute;
  bottom: 25%;

  @media screen and (max-width: base.$breakpoint-md) {
    margin-top: 120px;
    position: relative;
  }
}

.first_sub_section__mobile_image_box {
  display: flex;
  align-items: flex-end;
  position: relative;
  left: 3rem;

  img {
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-fit: cover;
    object-position: center;
  }

  span {
    writing-mode: vertical-rl;
    font-size: 10px;
    font-weight: bold;
    font-style: italic;

    transform: rotate(-180deg);
  }

  @media screen and (min-width: base.$breakpoint-md) {
    display: none;
  }
}
