@use "@styles/base";

// DESKTOP
.desktop_header {
  height: 70px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  background-color: base.$coffee-back;
  transition: opacity 0.2s ease-in-out;
  border-bottom: 1px solid black;

  @media screen and (max-width: base.$breakpoint-lg) {
    padding: 1rem 1rem;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    display: none;
  }
}

.desktop_header__account_button {
  width: 50px;
  height: 50px;
  background-color: base.$coffee-primary;
  color: base.$white;
  border: transparent;
  border-radius: 50%;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  position: relative;
  text-transform: uppercase;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: base.$red-primary;
  }
}

.header_hidden {
  opacity: 0;
  z-index: -10000;
}

.desktop_header__link_wrapper {
  flex: 1 1 auto;
  padding: 1rem 0;

  display: flex;
  justify-content: space-around;
}

.desktop_header__link {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;

  cursor: pointer;

  background: none;
  border: none;

  &:hover {
    color: base.$coffee-main;
  }

  @media (max-width: base.$breakpoint-md) {
    //margin-top: 15px;
  }
}

.desktop_header__register_button {
  width: 240px;
  height: 50px;
  cursor: pointer;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: base.$coffee-main;
  color: base.$white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.08em;
  transition: 0.4s ease-in-out;

  &:hover {
    background-color: base.$coffee-dark;
  }

  &.none_hover {
    background-color: base.$coffee-main;
  }
}

// MOBILE
.mobile_header {
  height: 64px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1500;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: base.$coffee-back;
  transition: opacity 0.2s ease-in-out;

  @media screen and (max-width: base.$breakpoint-md) {
    display: flex;
  }
}

.mobile_header__toggler {
  border: none;
  background: none;
}

.mobile_header__link_wrapper {
  display: grid;
  grid-template-columns: min-content;
  gap: 40px;
  padding: 85px 3rem 3rem;
  position: fixed;
  inset: 0;
  background-color: base.$coffee-main;
  z-index: 1500;
}

.mobile_header__link {
  width: 300px;

  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.02em;
  color: base.$coffee-light;
  cursor: pointer;
  background: none;
  border: none;
}

.mobile_header__register_button {
  width: 206px;
  height: 50px;
  padding: 5px;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: base.$coffee-dark;
  letter-spacing: 0.08em;
  color: base.$white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.mobile_header__close_button {
  border: none;
  background: transparent url("close-icon-beige.svg") no-repeat center/contain;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
}
